import { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "@mantine/core";
import {
	AssessmentData,
	AssessmentScenarioData,
	BaseData,
	ListForcePackageData,
	InputData,
	Optional,
	SystemSettingsTemplate,
} from "../../../types/filters";
import { defaultScenarioSettings } from "../defaultSettings";
import SettingsPanel from "../../../components/settings-panel/SettingsPanel";
import Preloader from "../../../components/common/Preloader";
import styled from "@emotion/styled/macro";
import AssessmentsList from "./AssessmentsList";
import { downloadForcePackageSettings } from "../../ForcePackages/downloadForcePackageSettings";
import * as Cesium from "cesium";
import makeRequest from "../../../common/makeRequest";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface IAssessmentPage {
	forcePackageData: ListForcePackageData[];
	assessmentsData: AssessmentData[];
	inputData: InputData;
	assessmentSettingsTemplate: SystemSettingsTemplate<AssessmentData>;
	scenarioSettingsTemplate: SystemSettingsTemplate<AssessmentScenarioData>;
	defaultSettings: Optional<AssessmentData, "id">;
	initializeFilters: () => Promise<any>;
	downloadAssessmentSettings: (assessmentId: number) => Promise<any>;
	downloadScenarioSettings: (scenarioId: number) => Promise<any>;
	addAssessment: (settings: Optional<AssessmentData, "id">) => Promise<any>;
	addScenario: (settings: Optional<AssessmentScenarioData, "id">) => Promise<any>;
	saveAssessment: (settings: Optional<AssessmentData, "id">) => Promise<any>;
	saveScenario: (settings: Optional<AssessmentScenarioData, "id">) => Promise<any>;
	deleteAssessment: (settingsId: number) => Promise<any>;
	deleteScenario: (settingsId: number) => Promise<any>;
	duplicateAssessment: (settingsId: number) => Promise<any>;
	duplicateScenario: (scenarioId: number) => Promise<any>;
}

const AssessmentPage: React.FC<IAssessmentPage> = ({
	assessmentsData,
	inputData,
	initializeFilters,
	downloadAssessmentSettings,
	downloadScenarioSettings,
	assessmentSettingsTemplate,
	scenarioSettingsTemplate,
	defaultSettings,
	addAssessment,
	addScenario,
	saveAssessment,
	saveScenario,
	deleteAssessment,
	deleteScenario,
	duplicateAssessment,
	duplicateScenario,
}) => {
	const { assessmentId, scenarioId } = useParams();
	const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
	const [assessmentsLoading, setAssessmentsLoading] = useState<boolean>(false);
	const [assessmentSettings, setAssessmentSettings] = useState<Optional<AssessmentData, "id">>();
	const [isNewAssessment, setIsNewAssessment] = useState<boolean>(false);

	const [scenariosData, setScenariosData] = useState<BaseData[] | undefined>();
	const [scenarioSettings, setScenarioSettings] = useState<Optional<AssessmentScenarioData, "id">>();
	const [scenariosLoading, setScenariosLoading] = useState<boolean>(false);
	const [isNewScenario, setIsNewScenario] = useState<boolean>(false);
	const [geometriesDataSource, setGeometriesDataSource] = useState<Cesium.GeoJsonDataSource | null>(null);
	const [geographyName, setGeographyName] = useState<string>("");

	const [latestAssessmentId, setLatestAssessmentId] = useState<number>(0); // in case of assessment configuration panel close (to refresh scenarios list)
	const wasInitializedFromUrl = useRef<boolean>(false);

	// useEffect(() => {
	// 	console.log("blue_behavior_settings", scenarioSettings?.blue_behavior_settings);
	// }, [scenarioSettings]);

	const downloadGeometries = useCallback(
		async (settingName?: keyof AssessmentScenarioData, value?: AssessmentScenarioData[keyof AssessmentScenarioData]) => {
			try {
				if (scenarioSettings) {
					if (settingName)
						settingName === "geography"
							? (scenarioSettings.geography = value as string)
							: (scenarioSettings.resolution = value as string);

					const response = await makeRequest(
						`geometry_voronois/get?geography=${scenarioSettings.geography.toLowerCase()}&resolution=${scenarioSettings.resolution.toLowerCase()}`,
						"GET"
					);

					if (response.ok) {
						const data = await response.json();
						const dataSource = await Cesium.GeoJsonDataSource.load(data, {
							stroke: Cesium.Color.WHITE.withAlpha(0.2),
							fill: Cesium.Color.WHITE.withAlpha(0.2),
							strokeWidth: 3,
						});

						if (data.features.length === 0) {
							toast.error("No geometries found for given geography and resolution");
						}
						setGeometriesDataSource(dataSource);
					} else {
						toast.error("No geometries found for given geography and resolution");
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				return Promise.resolve();
			}
		},
		[scenarioSettings]
	);

	useEffect(() => {
		if (
			scenarioSettings?.geography &&
			scenarioSettings?.resolution &&
			geographyName !== scenarioSettings.geography + scenarioSettings.resolution
		) {
			setGeometriesDataSource(null);
			setGeographyName(scenarioSettings.geography + scenarioSettings.resolution);
			if (scenarioSettings) downloadGeometries();
		}
	}, [
		geometriesDataSource,
		scenarioSettings?.geography,
		scenarioSettings?.resolution,
		scenarioSettings,
		downloadGeometries,
		geographyName,
	]);

	const getAssessmentSettings = async (assessmentId: number) => {
		setScenariosData(undefined);
		setIsNewAssessment(false);
		const json = await downloadAssessmentSettings(assessmentId);

		if (json) {
			const settings = { ...json, id: assessmentId } as AssessmentData;
			settings.programming_scenarios ? setScenariosData(settings.programming_scenarios) : setScenariosData([]);

			setLatestAssessmentId(assessmentId);
			setAssessmentSettings((prev) => {
				const oldId = prev?.id;
				if (assessmentId !== oldId && oldId !== undefined) setScenarioSettings(undefined);
				return settings;
			});
			return settings;
		} else {
			setLatestAssessmentId(0);
		}
	};

	const getScenarioSettings = async (scenarioId: number) => {
		setIsNewScenario(false);
		const settings = { ...(await downloadScenarioSettings(scenarioId)), id: scenarioId } as AssessmentScenarioData;
		setScenarioSettings(settings);
		setAssessmentSettings(undefined);
		return settings;
	};

	const downloadCorrespondingForcePackage = async (fp_id: number) => {
		const force_package_settings = await downloadForcePackageSettings(fp_id);
		if (force_package_settings) {
			return { force_package: force_package_settings.fp, systems: { ...force_package_settings.systems } };
		}
	};

	const reloadFilters = async () => {
		setFiltersLoading(true);
		const res = await initializeFilters();
		if (res) {
			if ((assessmentSettings && assessmentSettings.id) || latestAssessmentId) {
				const id = assessmentSettings?.id || latestAssessmentId;
				getAssessmentSettings(id);
			}
			setFiltersLoading(false);
			setIsNewScenario(false);
			setIsNewAssessment(false);
		}
	};

	const handleDeleteAssessment = async (assessmentId: number) => {
		const res = await deleteAssessment(assessmentId);
		if (assessmentSettings && assessmentSettings.id === assessmentId) setAssessmentSettings(undefined);
		return res;
	};

	const handleDeleteScenario = async (scenarioId: number) => {
		const res = await deleteScenario(scenarioId);
		if (scenarioSettings && scenarioSettings.id === scenarioId) setScenarioSettings(undefined);
		return res;
	};

	const updateScenarioBehaviorSettings = async (
		settingName: keyof AssessmentScenarioData,
		value: AssessmentScenarioData[keyof AssessmentScenarioData]
	) => {
		const fp = await downloadCorrespondingForcePackage(value as number);
		const agentBehaviorsProperty = settingName === "blue_force_package" ? "blue_behavior_settings" : "red_behavior_settings";
		const echelonBehaviorsProperty = settingName === "blue_force_package" ? "blue_echelon_settings" : "red_echelon_settings";
		setScenarioSettings(
			(prev) =>
				({
					...prev,
					[settingName]: value,
					...{ [agentBehaviorsProperty]: {} },
					...{ [echelonBehaviorsProperty]: {} },
					...{ systems: fp && prev ? { ...fp.systems, ...prev.systems } : {} },
				} as AssessmentScenarioData)
		);
	};

	useEffect(() => {
		// Download and open respective assessment or scenario if present in URL
		if (!wasInitializedFromUrl.current) {
			if (assessmentId) {
				getAssessmentSettings(parseInt(assessmentId));
				wasInitializedFromUrl.current = true;
			}
			if (scenarioId) {
				getScenarioSettings(parseInt(scenarioId));
				wasInitializedFromUrl.current = true;
			}
		}
		// Functions (getAssessmentSettings etc.) don't change and including them causes infinite loop
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentId, scenarioId]);

	return (
		<PageContainer>
			<ListContainer>
				<AssessmentsList
					name=""
					list={assessmentsData}
					scenariosList={scenariosData}
					filtersLoading={filtersLoading}
					getElementSettings={(scenarioId: number) => {
						setScenarioSettings(undefined);
						return getAssessmentSettings(scenarioId);
					}}
					getScenarioSettings={getScenarioSettings}
					setSettingsLoading={(value: boolean) => setAssessmentsLoading(value)}
					setScenariosLoading={(value: boolean) => setScenariosLoading(value)}
					reloadFilters={reloadFilters}
					initializeNewSystem={() => {
						setAssessmentSettings(defaultSettings);
						setIsNewAssessment(true);
					}}
					initializeNewScenario={async () => {
						setScenariosLoading(true);

						const blue_fp = await downloadCorrespondingForcePackage(defaultScenarioSettings.blue_force_package);
						const red_fp = await downloadCorrespondingForcePackage(defaultScenarioSettings.red_force_package);

						setScenarioSettings({
							...defaultScenarioSettings,
							id: assessmentSettings?.id,
							...{ systems: blue_fp && red_fp ? { ...blue_fp.systems, ...red_fp.systems } : {} },
						});

						setScenariosLoading(false);
						setIsNewScenario(true);
					}}
					deleteSystem={handleDeleteAssessment}
					deleteScenario={handleDeleteScenario}
					duplicateSystem={duplicateAssessment}
					duplicateScenario={duplicateScenario}
				/>
			</ListContainer>
			<SystemSettingWrapper>
				{scenarioSettings && Object.keys(scenarioSettings).length > 0 ? (
					<SettingsPanel
						key={2}
						settings={scenarioSettings}
						defaultSettings={defaultScenarioSettings}
						isSystemNew={isNewScenario}
						systemSettingsTemplate={scenarioSettingsTemplate}
						inputData={{ ...inputData, geometriesDataSource: geometriesDataSource }}
						settingsLoading={scenariosLoading}
						filtersLoading={filtersLoading}
						reloadFilters={reloadFilters}
						removeSettings={() => setScenarioSettings(undefined)}
						addSystem={addScenario}
						saveSystem={saveScenario}
						setSetting={async (
							settingName: keyof AssessmentScenarioData,
							value: AssessmentScenarioData[keyof AssessmentScenarioData]
						) => {
							setScenarioSettings((prev) => ({ ...prev, [settingName]: value } as AssessmentScenarioData));

							if (settingName === "blue_force_package" || settingName === "red_force_package") {
								setScenariosLoading(true);
								await updateScenarioBehaviorSettings(settingName, value);
								setScenariosLoading(false);
							}

							if (settingName === "geography" || settingName === "resolution") {
								setScenariosLoading(true);
								await downloadGeometries(settingName, value);
								setScenariosLoading(false);
							}
						}}
					/>
				) : scenariosLoading ? (
					<Preloader></Preloader>
				) : assessmentSettings && Object.keys(assessmentSettings).length > 0 ? (
					<SettingsPanel
						key={1}
						settings={assessmentSettings}
						defaultSettings={defaultSettings}
						isSystemNew={isNewAssessment}
						systemSettingsTemplate={assessmentSettingsTemplate}
						inputData={inputData}
						settingsLoading={assessmentsLoading}
						filtersLoading={filtersLoading}
						reloadFilters={reloadFilters}
						removeSettings={() => setAssessmentSettings(undefined)}
						addSystem={addAssessment}
						saveSystem={saveAssessment}
						setSetting={(settingName: keyof AssessmentData, value: AssessmentData[keyof AssessmentData]) =>
							setAssessmentSettings((prev) => ({ ...prev, [settingName]: value } as AssessmentData))
						}
					/>
				) : assessmentsLoading ? (
					<Preloader></Preloader>
				) : null}
			</SystemSettingWrapper>
		</PageContainer>
	);
};

export const PageContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	max-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--content-padding) * 2 - var(--breadcrumbs-height));

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const ListContainer = styled(Flex)`
	flex-direction: column;
	width: 30%;
	max-height: 100%;
	overflow-y: auto;

	& :first-of-type {
		margin-top: 0;
	}

	// & Panel:last-of-type {
	// 	height: 100%;
	// }

	@media (max-width: 768px) {
		max-height: 50vh;
		width: 100%;
	}
`;

const SystemSettingWrapper = styled(Flex)`
	flex-direction: column;
	width: 70%;
	max-height: 100%;
	overflow-y: auto;
	margin-left: 1rem;
	flex: 1;
	position: relative;

	& > :nth-of-type(2) {
		margin-top: 1rem;
	}

	@media (max-width: 768px) {
		width: 100%;
		margin: 0;
		margin-top: 1rem;
	}
`;

export default AssessmentPage;
