import { AssessmentData, AssessmentScenarioData, Optional, SystemSettingsTemplate } from "../../types/filters";

export const assessmentsSettingsTemplate: SystemSettingsTemplate<AssessmentData> = {
	sections: {
		Assessment: {
			name: "text",
		},
	},
};

export const scenariosSettingsTemplate: SystemSettingsTemplate<AssessmentScenarioData> = {
	sections: {
		Configuration: {
			blue_force_package: { type: "select", withAnchorButton: true, anchorLink: "/force-packages" },
			red_force_package: { type: "select", withAnchorButton: true, anchorLink: "/force-packages" },
			geography: { type: "select" },
			resolution: {
				type: "select",
				data: [
					{ value: "2D", label: "2D" },
					{ value: "3D", label: "3D" },
				],
			},
			mission: {
				type: "select",
				data: [
					{ value: "Force-on-force", label: "Force-on-force" },
					{ value: "Hybrid engagement", label: "Hybrid engagement" },
				],
			},
			crossborder_blue: "checkbox",
			rcs_calculation: { type: "checkbox", label: "Enable RCS Calculation" },
			bandwidth_limitations: { type: "checkbox", label: "Enable Emitter bandwidth calculation" },
			blue_headstart: { type: "slider", min_limit: -12, max_limit: 12, unit: "hours" },
			target_selection: {
				type: "select",
				data: [
					{ value: "centralized", label: "Centralized" },
					{ value: "selfAssigned", label: "Self-assigned" },
				],
			},
		},
		Confounders: {
			confounders: {
				type: "static-table",
				columns: { type: "Type", degradation_percent: "Degradation (%)" },
				use_archetypes: false,
				editable: false,
			},
		},
		Zones: {
			zones: {
				type: "zones",
			},
		},
		"Blue Behaviors": {
			blue_behavior_settings: "force-package-behaviors",
			blue_echelon_settings: { type: "none" },
		},
		"Red Behaviors": {
			red_behavior_settings: "force-package-behaviors",
			red_echelon_settings: { type: "none" },
			systems: { type: "none" },
		},

		"Programming scenario": {
			name: "text",
		},
	},
};

export const defaultSettings: Optional<AssessmentData, "id"> = {
	name: "New assessment",
};

export const defaultScenarioSettings: Optional<AssessmentScenarioData, "id"> = {
	blue_force_package: 0,
	red_force_package: 0,
	geography: "Basel",
	resolution: "2D",
	mission: "Force-on-force",
	blue_behavior_settings: {},
	red_behavior_settings: {},
	blue_echelon_settings: {},
	red_echelon_settings: {},
	systems: {},
	crossborder_blue: false,
	rcs_calculation: false,
	blue_headstart: 0,
	confounders: [],
	target_selection: "selfAssigned",
	name: "New scenario",
};
