import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	FiltersData,
	ListForcePackageData,
	InputData,
	Optional,
	SystemData,
	Behavior,
	SystemSettingsBaseTypes,
	SystemSettingsObjectType,
} from "../../../types/filters";
import { defaultSettings, systemsSettingsTemplate } from "./defaultSettings";
import { toast } from "react-toastify";
import makeRequest from "../../../common/makeRequest";
import Preloader from "../../../components/common/Preloader";
import SystemPage from "../../../components/system-page/SystemPage";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";

const Systems: React.FC = () => {
	const [filters, setFilters] = useState<FiltersData>();
	const [inputData, setInputData] = useState<InputData>();
	const { currentPid } = useContext(AppInfoContext);

	const initialize = useCallback(async (pid: number, fp: number | string, side: number | string) => {
		const filters = await downloadFilters(pid, fp, side);
		const inpData = await downloadInputData();
		setFilters(filters);
		setInputData(inpData);
		return true;
	}, []);

	const downloadFilters = async (pid: number, fp: number | string, side: number | string) => {
		const response = await makeRequest("systems/get", "GET", {}, { pid: pid, fp: fp, side: side });
		const json = await response.json();
		return json as FiltersData;
	};

	const downloadSystemSettings = async (systemId: number) => {
		const response = await makeRequest(`systems/${systemId}/get`, "GET");
		const json = await response.json();
		return json.system as SystemData;
	};

	const downloadInputData = async () => {
		const response = await makeRequest("systems/get_input_data", "GET");
		const json = await response.json();
		return json as InputData;
	};

	const addSystem = async (settings: Optional<SystemData, "id">) => {
		const res = await makeRequest("systems/add", "POST", settings);
		if (res.ok) {
			toast.success("System added successfully!");
			const json = await res.json();
			return json.system as SystemData;
		}
		toast.error("Error adding system!");
		return null;
	};

	const deleteSystem = async (settingsId: number) => {
		const res = await makeRequest(`systems/${settingsId}/delete`, "POST", {});
		if (res.ok) {
			toast.success("System deleted successfully!");
			const json = await res.json();
			return json;
		}
		toast.error("Error deleting system!");
		return null;
	};

	const saveSystem = async (settings: Optional<SystemData, "id">) => {
		const res = await makeRequest(`systems/${settings.id}/save`, "POST", settings);
		if (res.ok) {
			toast.success("System saved successfully!");
			const json = await res.json();
			return json as SystemData;
		}
		toast.error("Error saving system!");
		return null;
	};

	const duplicateSystem = async (settingsId: number) => {
		const res = await makeRequest(`systems/${settingsId}/duplicate`, "POST", {});
		if (res.ok) {
			toast.success("System duplicated successfully!");
			const json = await res.json();
			return json as SystemData;
		}
		toast.error("Error duplicating system!");
		return null;
	};

	const getSystemUsageInfo = async (settingsId: number, pid: number) => {
		const res = await makeRequest(`systems/${settingsId}/get_usage_info`, "GET", undefined, { pid: pid });
		if (res.ok) {
			const json = await res.json();
			return json as SystemData;
		}
		toast.error("Error obtaining usage informations!");
		return null;
	};

	useEffect(() => {
		if (inputData && systemsSettingsTemplate) {
			const data = {
				type: "select" as SystemSettingsBaseTypes,
				data: (inputData.behaviors as Behavior[]).map((behavior: Behavior) => {
					return { value: behavior.name, label: behavior.name };
				}),
			};
			systemsSettingsTemplate.sections["Behaviors"].blue_subordinate_behavior = data;
			systemsSettingsTemplate.sections["Behaviors"].red_subordinate_behavior = data;
			systemsSettingsTemplate.sections["Behaviors"].blue_commander_behavior = data;
			systemsSettingsTemplate.sections["Behaviors"].red_commander_behavior = data;
		}

		if (inputData && inputData.roles && systemsSettingsTemplate) {
			const data = inputData.roles.map((role: string) => {
				return { value: role, label: role };
			});
			(systemsSettingsTemplate.sections["Marks"].roles as SystemSettingsObjectType).data = data;
		}
	}, [inputData]);

	useEffect(() => {
		initialize(currentPid, "", "");
	}, [initialize, currentPid]);

	return filters && inputData ? (
		<SystemPage
			forcePackageData={filters.fps as ListForcePackageData[]}
			systemsData={filters.systems as SystemData[]}
			inputData={inputData}
			initializeFilters={initialize}
			downloadSystemSettings={downloadSystemSettings}
			systemSettingsTemplate={systemsSettingsTemplate}
			defaultSettings={defaultSettings}
			addSystem={addSystem}
			saveSystem={saveSystem}
			deleteSystem={deleteSystem}
			duplicateSystem={duplicateSystem}
			getSystemUsageInfo={getSystemUsageInfo}
		></SystemPage>
	) : (
		<Preloader></Preloader>
	);
};

export default Systems;
