export const filterListBySearchValue = <T extends { name?: string; specific_name?: string; type_name?: string }>(
	list: T[],
	searchValue: string | null
): T[] => {
	return list.filter((element) => {
		if (searchValue === null) return true;
		return (
			element.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
			element.specific_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
			element.type_name?.toLowerCase().includes(searchValue.toLowerCase())
		);
	});
};
