import { Optional, SystemData, SystemSettingsTemplate } from "../../../types/filters";

export const systemsSettingsTemplate: SystemSettingsTemplate<SystemData> = {
	sections: {
		"": {
			platform: { type: "radio-group" },
		},
		Marks: {
			set_time: { type: "slider", max_limit: 60, unit: "min" },
			take_off_time: { type: "slider", max_limit: 60, unit: "min" },
			time_to_ready_to_fire: { type: "slider", max_limit: 60, unit: "min" },
			dismount_time: { type: "slider", max_limit: 60, unit: "min" },
			armor: {
				type: "select",
				data: [
					{ value: "none", label: "None" },
					{ value: "active", label: "Active" },
					{ value: "passive", label: "Passive" },
					{ value: "reactive", label: "Reactive" },
				],
			},
			roles: { type: "multi-checkbox", data: [] },
		},
		Sensors: {
			sensors: {
				type: "static-table",
				columns: { name: "Sensor" },
				use_archetypes: false,
				editable: false,
			},
		},
		Emitters: {
			emitters: {
				type: "static-table",
				columns: { name: "Emitter" },
				use_archetypes: false,
				editable: false,
			},
		},
		Effectors: {
			effectors: {
				type: "static-table",
				columns: { name: "Effector" },
				use_archetypes: false,
				editable: false,
			},
		},
		Dismounts: {
			dismounts: {
				type: "static-table",
				columns: { name: "Dismount" },
				use_archetypes: false,
				editable: false,
			},
		},
		Behaviors: {
			blue_subordinate_behavior: { type: "select" },
			red_subordinate_behavior: { type: "select" },
			blue_commander_behavior: { type: "select" },
			red_commander_behavior: { type: "select" },
		},
		Sensor: {
			side: {
				type: "select",
				data: [
					{ value: "2", label: "NONE" },
					{ value: "0", label: "BLUE" },
					{ value: "1", label: "RED" },
				],
			},
			cmo_id: "text",
			cost: "text",
			symbol: "text",
			name: "text",
		},
	},
};

export const defaultSettings: Optional<SystemData, "id"> = {
	set_time: 0,
	armor: "none",
	side: 0,
	cmo_id: -1,
	cost: 0,
	name: "New system",
	roles: [],
	emitters: [],
	sensors: [],
	effectors: [],
	dismounts: [],
	symbol: "",
	platform: null,
	id: 0,
	count: 0,
	filtered: false,
	blue_subordinate_behavior: "",
	red_subordinate_behavior: "",
	blue_commander_behavior: "",
	red_commander_behavior: "",
};
