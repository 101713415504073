import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppInfoContext } from "../../components/contexts/AppInfoContext";
import { Link } from "react-router-dom";
import { Button, useMantineTheme } from "@mantine/core";
import styled from "@emotion/styled/macro";
import Preloader from "../../components/common/Preloader";
import { OtherProjectsButtons } from "../../components/common/OtherProjectsButtons";

const PROJECTS_GRID_SIZE = 3;

const Landing: React.FC = () => {
	const theme = useMantineTheme();
	const [renderProjects, setRenderProjects] = useState<boolean>(false); //animate projects on render
	const projectsSectionRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { projects, setPid } = useContext(AppInfoContext);

	useEffect(() => {
		if (projectsSectionRef.current) projectsSectionRef.current.style.maxHeight = "40px";
	}, []);

	useEffect(() => {
		if (projects && projects.length > 0) {
			setTimeout(() => {
				setRenderProjects(true);
				if (projectsSectionRef.current) projectsSectionRef.current.style.maxHeight = "100vh";
			}, 100);
		}
	}, [projects]);

	return (
		<LandingContainer>
			<IntroductionSection>
				<h2>Welcome to OPTIMA</h2>
				<p>
					OPTIMA is a multi-agent, AI-enabled constructive simulation designed and built to analyze adversarial encounters in
					different scenarios.
				</p>
				<p>
					OPTIMA in particular supports procurement, the testing of concept of operations, operational planning, and the design of
					armed forces.
				</p>
				<p>
					armasuisse S+T commissioned the developed of OPTIMA. OPTIMA was built in collaboration with armasuisse S+T and A Plan.
				</p>
				<p>In the Crafter configure and parameterize systems.</p>
				<p>In Projects design force packages and assessments and access results from previous projects.</p>
				<h2>Resources</h2>
				<p>
					For an explanation of simulation and military terms used in OPTIMA follow the <Link to={"resources"}>link</Link>.
				</p>
			</IntroductionSection>
			<MenuSection>
				<h2>Projects</h2>
				<ProjectsSection ref={projectsSectionRef}>
					{projects ? (
						projects?.map((project) => (
							<Button
								className={renderProjects ? "show" : "hide"}
								key={project.id}
								onClick={() => {
									setPid(project.id);
									navigate("/project/" + project.id);
								}}
							>
								{project.name}
							</Button>
						))
					) : (
						<Preloader customStyles={`grid-column: span ${PROJECTS_GRID_SIZE};`}></Preloader>
					)}
				</ProjectsSection>
				<h2>Crafter</h2>
				<CrafterSection>
					<Button
						onClick={() => {
							setPid(0);
							navigate("/systems-library");
						}}
					>
						{"Systems Library"}
					</Button>
				</CrafterSection>
				<h2>Other</h2>
				<OtherSection>
					<OtherProjectsButtons reporterUrl="/reporter" cesiumUrl="/cesium" />
				</OtherSection>
			</MenuSection>
		</LandingContainer>
	);
};

const IntroductionSection = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	padding-right: 2rem;
	width: 50%;

	& > p {
		color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
		padding: 2rem 0 0 0;
		font-size: 16px;
	}

	& p:last-child {
		padding-bottom: 2rem;
	}

	& > h2:last-of-type {
		padding-top: 2rem;
	}

	@media (max-width: 768px) {
		width: 100%;
		padding-right: 0;
	}
`;

const MenuSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	height: fit-content;
	padding-left: 2rem;

	& h2 {
		padding: 1rem 0;
	}

	@media (max-width: 768px) {
		width: 100%;
		padding-left: 0;
	}
`;

const ProjectsSection = styled.div`
	display: grid;
	grid-template-columns: repeat(${PROJECTS_GRID_SIZE}, 1fr);
	transition: 0.5s ease-in-out max-height;

	& button {
		height: 6rem;
		transition: 0.5s ease-in-out opacity;
	}

	gap: 1rem;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		& button {
			margin: 1rem 0;
			height: 6rem;
			transition: 0.5s ease-in-out opacity;
		}
	}
`;

const CrafterSection = styled.div`
	display: grid;
	grid-template-columns: 1fr;

	& h2 {
		width: 100%;
	}

	& button:first-of-type {
		margin-left: 0;
	}

	& button:last-child {
		margin-right: 0;
	}

	& button {
		// margin: 1rem;
		height: 6rem;
	}
`;

const OtherSection = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	& button:first-of-type {
		margin-left: 0;
	}

	& button:last-child {
		margin-right: 0;
	}

	& button {
		margin: 0 1rem;
		height: 6rem;
	}
`;

const LandingContainer = styled.div`
	display: flex;
	height: 100%;

	@media (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

export default Landing;
