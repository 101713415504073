import React from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, createStyles } from "@mantine/core";
import { filterListBySearchValue } from "../../common/filterListBySearchValue";
import { AutocompleteWithoutSuggestions } from "../../components/elements-list/ElementsList";

declare const require: {
	context(
		directory: string,
		useSubdirectories: boolean,
		regExp: RegExp
	): {
		keys: () => string[];
		<T>(id: string): T;
	};
};

const nameMapper: Record<string, string> = {
	"Auswertung OPTIMA GMB ext II Slidedoc 10052021 FINAL":
		'Resultate Simulation OPTIMA GMB ext II Optionenvergleich "Indirekt" und "Mix Direkt-Indirekt',

	"Guideline V&V AI MAS 14012024 FINAL":
		"Aligning Levels of War with Levels of Complexity and Levels of Verification and Validation: A Pragmatic Guideline for Verification and Validation for Data Farming in Support of Force Design via AI-driven MAS",

	"OPTIMA AD DUE Workshop Slides Armando 31012023 FINAL": "OPTIMA AD DUE: Beitrag Workshop Scensei",

	"OPTIMA AD UNO Final report FINAL 15092022": "OPTIMA AD UNO",

	"OPTIMA Bodluv Slidedoc 11062021 FINAL update": "SIMULATION OPTIMA Bodluv Schlussbericht",

	"OPTIMA ext Slidedoc 28012021 FINAL": "OPTIMA GMB ext",

	OPTIMA_GMB_Final_RUMI_19_08_2020_signed:
		'OPTIMA GMB - An Explorative Simulation Study to Support Decision Making in the Context of "GLP Geschützte Mittel am Boden (GMB)',

	"Q1 2-Pager 03102022 FINAL": "OPTIMA AD DUE: Q1 Executive Summary",

	"Q2 2-Pager 04102022 v2.0 DRAFT": "OPTIMA AD DUE: Q2 Executive Summary",

	"Q3&Q4 2-Pager 08112022 FINAL": "OPTIMA AD DUE: Q3 & Q4 Executive Summary",

	"Q5 2-Pager FINAL 08112022": "OPTIMA AD DUE: Q5 Executive Summary",

	glossary: "Glossary",

	optima_ai: "Report: OPTIMA, Artificial Intelligence and Trends in Milsim",

	optima_sdd: "Software design document",
};

const ResourcesMenu: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = React.useState<string | null>(null);

	const pdfsContext = require.context("../../assets", false, /\.pdf$/);
	const pdfs = pdfsContext.keys().map((file) => {
		const fileName = file.replace("./", "").replace(".pdf", "");
		return {
			name: nameMapper[fileName] || formatFileName(fileName),
			path: pdfsContext(file) as string,
		};
	});

	function formatFileName(fileName: string) {
		return fileName.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (letter) => letter.toUpperCase());
	}

	return (
		<MenuContainer>
			<AutocompleteWithoutSuggestions
				w={"100%"}
				placeholder="Search"
				data={pdfs.map((pdf: { name: string; path: string }) => pdf.name)}
				onChange={(value) => {
					setSearchValue(value);
				}}
				mb={"1rem"}
				limit={0}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						e.currentTarget.blur();
					}
				}}
			></AutocompleteWithoutSuggestions>
			{filterListBySearchValue(pdfs, searchValue).map((pdf: { name: string; path: string }) => (
				<Button key={pdf.name} className={classes.menuBtn} variant="outline" component="a" href={pdf.path} target="_blank">
					{pdf.name}
				</Button>
			))}
			<Button className={classes.menuBtn} variant="outline" onClick={() => navigate("symbols")}>
				{"Military symbols"}
			</Button>
		</MenuContainer>
	);
};

const useStyles = createStyles((theme) => ({
	menuBtn: {
		width: "100%",
		height: "3rem",

		"& .mantine-Button-label": {
			whiteSpace: "break-spaces",
			// textAlign: "left",
		},

		"& .mantine-Button-inner": {
			justifyContent: "flex-start",
		},
	},
}));

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	padding: 0 10rem;
	justify-content: center;
    gap: 1rem;

	& button, a {
		// width: 100%;
	}

	@media (max-width: 768px) {
		padding: 0;
		& button, a {
			width: 12rem;
			height: 12rem;
		}
	}
}`;

export default ResourcesMenu;
