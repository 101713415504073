import * as Cesium from "cesium";

export function resetAreaColor(entity: Cesium.Entity, opacity?: number) {
	if (entity.polygon && entity.polygon.material) {
		const entityColor =
			entity?.properties?.type._value.indexOf("Blue") !== -1
				? Cesium.Color.BLUE.withAlpha(opacity || 0.1)
				: Cesium.Color.RED.withAlpha(opacity || 0.1);
		entity.polygon.material = new Cesium.ColorMaterialProperty(entityColor);
	}
}
