import { Checkbox, Group, Text } from "@mantine/core";
import React from "react";
import { SelectItem } from "../../types/filters";

interface IMultiCheckbox {
	keyProp: string;
	options: SelectItem[];
	checkedList: (string | number)[];
	additionalClassName?: string;
	onChange: (checkedList: (string | number)[]) => void;
}

const MultiCheckbox: React.FC<IMultiCheckbox> = ({ keyProp, options, checkedList, additionalClassName, onChange }) => {
	return (
		<Group key={keyProp} className={additionalClassName}>
			{(options as Array<SelectItem>).map((element, i) => (
				<Checkbox
					key={i}
					label={element.label ? element.label.split("_").join(" ") : element.value}
					defaultChecked={checkedList.includes(element.value)}
					onChange={(value) => {
						if (value.target.checked) {
							onChange([...checkedList, element.value]);
						} else onChange(checkedList.filter((checkboxName) => checkboxName !== element.value));
					}}
				></Checkbox>
			))}
			{options.length === 0 && <Text style={{ fontSize: "14px" }}>No protocols available</Text>}
		</Group>
	);
};

export default MultiCheckbox;
