import { useContext, useEffect, useRef, useState } from "react";
import { Button, Center, Flex, createStyles } from "@mantine/core";
import { BaseData } from "../../../types/filters";
import { Panel } from "../../../AppPage";
import { AppInfoContext } from "../../../components/contexts/AppInfoContext";
import Preloader from "../../../components/common/Preloader";
import styled from "@emotion/styled/macro";
import { IElementsList } from "../../../types/common";
import ListElement from "../../../components/elements-list/ListElement";
import { useNavigate, useParams } from "react-router-dom";
import { AutocompleteWithoutSuggestions } from "../../../components/elements-list/ElementsList";
import { filterListBySearchValue } from "../../../common/filterListBySearchValue";

interface IAssessmentsList<T extends BaseData> extends IElementsList<T> {
	scenariosList: BaseData[] | undefined;
	getScenarioSettings: (scenarioId: number) => Promise<any>;
	initializeNewScenario: () => void;
	deleteScenario: (settingsId: number) => Promise<any>;
	duplicateScenario: (scenarioId: number) => Promise<any>;
	setScenariosLoading: (value: boolean) => void;
}

const AssessmentsList = <T extends BaseData>({
	list,
	scenariosList,
	filtersLoading,
	getElementSettings,
	getScenarioSettings,
	setSettingsLoading,
	setScenariosLoading,
	initializeNewSystem,
	initializeNewScenario,
	deleteSystem,
	deleteScenario,
	duplicateSystem,
	duplicateScenario,
	reloadFilters,
}: IAssessmentsList<T>) => {
	const navigate = useNavigate();
	const { assessmentId, scenarioId } = useParams();
	const { classes } = useStyles();
	const { isSuperuser } = useContext(AppInfoContext);
	const [currentList, setCurrentList] = useState<T[]>(list);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [currentScenariosList, setCurrentScenariosList] = useState<BaseData[] | undefined>(scenariosList);
	const ongoingAnimations = useRef<number>(0);
	const animationStartTime = useRef<CSSNumberish | null>(null);
	const openedAssessmentId = useRef<number | null>(assessmentId ? parseInt(assessmentId) : null);
	const openedScenarioId = useRef<number | null>(scenarioId ? parseInt(scenarioId) : null);

	useEffect(() => {
		setCurrentList(list);
	}, [list]);

	useEffect(() => {
		setCurrentScenariosList(scenariosList);
	}, [scenariosList]);

	const handleDeleteSystem = async (settingsId: number) => {
		const res = await deleteSystem(settingsId);
		if (res) {
			ongoingAnimations.current -= 1;
			setCurrentList((prev) => prev.filter((element) => element.id !== settingsId));
			if (ongoingAnimations.current === 0) {
				animationStartTime.current = null;
				reloadFilters();
			}
		}
		return res;
	};

	const handleDeleteScenario = async (settingsId: number) => {
		const res = await deleteScenario(settingsId);
		if (res) {
			ongoingAnimations.current -= 1;
			setCurrentScenariosList((prev) => (prev ? prev.filter((element) => element.id !== settingsId) : undefined));
			if (ongoingAnimations.current === 0) {
				animationStartTime.current = null;
				reloadFilters();
			}
		}
		return res;
	};

	const handleDuplicateSystem = async (settingsId: number) => {
		const res = await duplicateSystem(settingsId);
		if (res) reloadFilters();
		return res;
	};

	const handleDuplicateScenario = async (settingsId: number) => {
		const res = await duplicateScenario(settingsId);
		if (res) reloadFilters();
		return res;
	};

	return (
		<PanelContainer>
			{!filtersLoading ? (
				<>
					<AutocompleteWithoutSuggestions
						label=""
						placeholder="Search"
						data={currentList.map((element) => element.name || element.specific_name || element.type_name || "unknown")}
						onChange={(value) => {
							setSearchValue(value);
						}}
						mb={"1rem"}
						limit={0}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								e.currentTarget.blur();
							}
						}}
					/>

					<ListWrapper>
						{filterListBySearchValue(currentList, searchValue).map((element, ix) => {
							return (
								<div key={ix}>
									<ListElement
										key={element.id}
										name={element.name || element.type_name || element.specific_name || "unknown"}
										id={element.id}
										side={element.side !== undefined ? element.side : 2}
										getElementSettings={async () => {
											setSettingsLoading(true);
											openedAssessmentId.current = element.id;
											openedScenarioId.current = null;
											const settings = await getElementSettings(element.id);
											if (settings) setSettingsLoading(false);
											navigate(`../assessments/${element.id}`, { replace: true });
										}}
										deleteSystem={handleDeleteSystem}
										duplicateSystem={handleDuplicateSystem}
										incrementOngoingAnimations={(startTime: CSSNumberish | null) => {
											ongoingAnimations.current += 1;
											if (ongoingAnimations.current === 1) animationStartTime.current = startTime || null;
											return animationStartTime.current;
										}}
										additionalClasses={openedAssessmentId.current === element.id ? classes.chosenListElement : ""}
										iconsList={["chart", "edit", "duplicate", "delete"]}
									></ListElement>

									{openedAssessmentId.current === element.id && currentScenariosList ? (
										<>
											<SubListWrapper key={"sublist"}>
												{currentScenariosList.map((scenario) => {
													return (
														<ListElement
															key={scenario.id}
															name={
																scenario.name || scenario.type_name || scenario.specific_name || "unknown"
															}
															id={scenario.id}
															side={scenario.side !== undefined ? scenario.side : 2}
															getElementSettings={async () => {
																setScenariosLoading(true);
																openedScenarioId.current = scenario.id;
																const settings = await getScenarioSettings(scenario.id);
																if (settings) setScenariosLoading(false);
																navigate(`../assessments/${element.id}/${scenario.id}`, { replace: true });
															}}
															deleteSystem={handleDeleteScenario}
															duplicateSystem={handleDuplicateScenario}
															incrementOngoingAnimations={(startTime: CSSNumberish | null) => {
																ongoingAnimations.current += 1;
																if (ongoingAnimations.current === 1)
																	animationStartTime.current = startTime || null;
																return animationStartTime.current;
															}}
															additionalClasses={
																openedScenarioId.current === scenario.id ? classes.chosenSublistElement : ""
															}
														></ListElement>
													);
												})}
											</SubListWrapper>
											{isSuperuser ? (
												<Center pb={"0.5rem"} key={"sublist-center"}>
													<Button
														key={"sublist-button"}
														size="xs"
														variant="outline"
														onClick={() => initializeNewScenario()}
														w={"fit-content"}
													>
														Add new scenario
													</Button>
												</Center>
											) : null}
										</>
									) : openedAssessmentId.current === element.id ? (
										<Preloader></Preloader>
									) : null}
								</div>
							);
						})}
					</ListWrapper>

					{isSuperuser ? (
						<Center pt={"1rem"}>
							<Button onClick={() => initializeNewSystem()} w={"fit-content"}>
								Add new
							</Button>
						</Center>
					) : null}
				</>
			) : (
				<Preloader></Preloader>
			)}
		</PanelContainer>
	);
};

const useStyles = createStyles((theme) => ({
	chosenListElement: {
		color: `${theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2]} !important`,
		fontWeight: "bold",
	},
	chosenSublistElement: {
		fontWeight: "bold",
	},
}));

const PanelContainer = styled(Panel)`
	overflow-y: auto;
	scrollbar-gutter: auto;
	height: fit-content;
	margin-top: 1rem;
`;

const ListWrapper = styled(Flex)`
	flex-direction: column;
	overflow-y: auto;
	scrollbar-gutter: stable;
	padding-bottom: 5px;

	@media (max-width: 768px) {
	}
`;

const SubListWrapper = styled(Flex)`
	flex-direction: column;
	padding-left: 1rem;
	border-left: 2px solid ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.light[3])};
	margin-bottom: 5px;

	@media (max-width: 768px) {
	}
`;

export default AssessmentsList;
