import { BehaviorParameter, InputData } from "../../../../../types/filters";
import _ from "lodash";

export const getBehaviorTemplate = (inputData: InputData, behaviorName: string | undefined) => {
	return inputData.behaviors?.find((behavior) => behavior.name === behaviorName)?.template;
};

// Action Queue functions

export const getQueueEntry = (queueSetting: BehaviorParameter | undefined) => {
	return findENTRYProperty(queueSetting?.settings);
};

export const getNextQueueEntry = (entry: BehaviorParameter | undefined) => {
	return findENTRYProperty(findNEXTProperty(entry?.settings)?.settings);
};

export const getQueuePlanningAction = (queueSetting: BehaviorParameter | undefined) => {
	return getPlanningAction(getQueueEntry(queueSetting)?.settings);
};

export const getQueueName = (queueSetting: BehaviorParameter | undefined) => {
	return getBehaviorName(getPlanningAction(getQueueEntry(queueSetting)?.settings));
};

export const getPreviousQueuePropertyFromPath = (agentBehaviorSettings: BehaviorParameter[], path: (string | number)[]) => {
	return getPlanningAction(_.get(agentBehaviorSettings, path.slice(0, -7)))?.settings?.[1]; // Assuming that index no. 1 is always the main input (0 is for name)
};

export const getCurrentQueueProperty = (entry: BehaviorParameter | undefined) => {
	return findPLANNING_ACTIONProperty(entry?.settings)?.settings?.[1];
};

// Behavior functions

export const getPlanningAction = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "PLANNING_ACTION");
};

export const getMovingBehavior = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "MOVING_BEHAVIOR");
};

export const getBehaviorName = (behavior: BehaviorParameter | undefined): string => {
	return findNAMEProperty(behavior?.settings)?.value as string;
};

export const getDependsOnProperty = (behavior: BehaviorParameter | undefined, dependsOnProperty: string | undefined) => {
	return behavior?.settings?.find((setting) => setting.property === dependsOnProperty);
};

// Helper functions

const findNAMEProperty = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "NAME");
};

export const findNEXTProperty = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "NEXT");
};

const findENTRYProperty = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "ENTRY");
};

const findPLANNING_ACTIONProperty = (settings: BehaviorParameter[] | undefined) => {
	return settings?.find((setting) => setting.property === "PLANNING_ACTION");
};
