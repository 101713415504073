import React, { useEffect, useState } from "react";
import { AreasSelector } from "./areas-selector/AreasSelector";
import makeRequest from "../../../../common/makeRequest";
import { toast } from "react-toastify";
import { AssessmentScenarioData, Optional } from "../../../../types/filters";
import { AreasData } from "../../../../types/geometries";
import { AreasEditor } from "./areas-editor/AreasEditor";
import { Flex, Loader } from "@mantine/core";

interface IAreasManager {
	value: number[];
	scenarioSettings: Optional<AssessmentScenarioData, "id">;
	onChange: (value: number[]) => void;
}

export const AreasManager: React.FC<IAreasManager> = ({ value, scenarioSettings, onChange }) => {
	const [areasData, setAreasData] = useState<AreasData>();
	const [reloadData, setReloadData] = useState(true);

	const addArea = async (areaProperties: { id: number; type: string; label: string; owner: string; corridor: string }, geometry: any) => {
		const response = await makeRequest("areas/add", "POST", {
			properties: areaProperties,
			geometry: geometry,
			geography: scenarioSettings.geography.toLowerCase(),
			resolution: scenarioSettings.resolution.toLowerCase(),
		});

		if (response.ok) {
			setReloadData(true);
			toast.success("Area added successfully");
		} else {
			toast.error("Failed to add area");
		}
	};

	const deleteArea = async (areaId: number) => {
		const response = await makeRequest("areas/delete", "POST", {
			id: areaId,
			geography: scenarioSettings.geography.toLowerCase(),
			resolution: scenarioSettings.resolution.toLowerCase(),
		});

		if (response.ok) {
			setReloadData(true);
			toast.success("Area removed successfully");
		} else {
			toast.error("Failed to remove area");
		}
	};

	const editArea = async (
		areaId: number,
		areaProperties: { id: number; type: string; label: string; owner: string; corridor: string }
	) => {
		const response = await makeRequest("areas/edit", "POST", {
			id: areaId,
			properties: areaProperties,
			geography: scenarioSettings.geography.toLowerCase(),
			resolution: scenarioSettings.resolution.toLowerCase(),
		});

		if (response.ok) {
			setReloadData(true);
			toast.success("Area edited successfully");
		} else {
			toast.error("Failed to edit area");
		}
	};

	useEffect(() => {
		const downloadAreas = async () => {
			try {
				const response = await makeRequest(
					`areas/get?geography=${scenarioSettings.geography.toLowerCase()}&resolution=${scenarioSettings.resolution.toLowerCase()}`,
					"GET"
				);

				if (response.ok) {
					const data = await response.json();

					if (data["features"].length === 0) {
						toast.error("No areas found for given geography and resolution");
					}

					setReloadData(false);
					setAreasData(data);
				} else {
					toast.error("No areas found for given geography and resolution");
				}
			} catch (e) {
				console.log(e);
			} finally {
				return Promise.resolve();
			}
		};

		if (reloadData === true) downloadAreas();
	}, [reloadData, scenarioSettings]);

	return (
		<Flex gap={"1rem"} direction="column">
			{areasData ? (
				<>
					<AreasSelector value={value} areasData={areasData} onChange={onChange} />
					<AreasEditor
						value={value}
						areasData={areasData}
						addArea={addArea}
						deleteArea={deleteArea}
						editArea={editArea}
						onChange={onChange}
					/>
				</>
			) : (
				<Loader />
			)}
		</Flex>
	);
};
