import { useState, useEffect, useContext } from "react";
import { Button, Flex, useMantineTheme, ActionIcon } from "@mantine/core";
import { BehaviorParameter, BehaviorParameterTemplate, InputData } from "../../../../../../../types/filters";
import _ from "lodash";
import { IconX } from "@tabler/icons-react";
import { BehaviorEditiorContext } from "../../../contexts/BehaviorEditiorContext";
import { BehaviorSelect } from "../../BehaviorPanel";
import {
	findNEXTProperty,
	getBehaviorName,
	getBehaviorTemplate,
	getNextQueueEntry,
	getPlanningAction,
	getQueueEntry,
	getQueueName,
	getQueuePlanningAction,
} from "../../../functions/behaviorSettingsFunctions";

interface IQueueInput {
	parameterTemplate: BehaviorParameterTemplate;
	disabled: boolean;
	inputData: InputData;
	behaviorSettings: Record<number, BehaviorParameter[]>;
	agentId: number;
	previewMode?: boolean;
	handleBehaviorChange: (
		behaviorType: "MOVING_BEHAVIOR" | "PLANNING_ACTION",
		value: string | null,
		path: (string | number)[],
		queueInputIndex?: number
	) => void;
	getParameterInput: (
		parameterTemplate: BehaviorParameterTemplate,
		keyIndex: number,
		path: (string | number)[],
		disabledParent: boolean,
		showName?: boolean,
		queueInputIndex?: number
	) => false | JSX.Element | JSX.Element[];
	getDefaultParameters: (behavior_name: string) => BehaviorParameter[];
	setSetting: (value: Record<number, BehaviorParameter[]> | null, duplicate?: boolean, changeTopLevelName?: boolean) => void;
	onChange: (value: any) => void;
}

const QueueInput: React.FC<IQueueInput> = ({
	parameterTemplate,
	disabled,
	inputData,
	behaviorSettings,
	agentId,
	previewMode,
	getDefaultParameters,
	getParameterInput,
	handleBehaviorChange,
	setSetting,
	onChange,
}) => {
	const theme = useMantineTheme();
	const { resetAgentIdEntities } = useContext(BehaviorEditiorContext);
	const schemeColors = theme.colors[theme.colorScheme === "dark" ? "dark" : "light"];
	const [updatedTemplate, setUpdatedTemplate] = useState<BehaviorParameterTemplate>(parameterTemplate);

	// Template of the queue behavior is always the same, but needs to change based on the elements we add to queue in order to show inputs for those elements
	// So for queue input we need to adjust the 'depth' of the template based on the elements that are in the settings
	// This is the function that does that at the beginning of the component
	useEffect(() => {
		const adjustTemplateRecursively = (template: BehaviorParameterTemplate, entry: BehaviorParameter) => {
			if (entry && entry.settings) {
				// If the template has no settings, but the entry does, we need to adjust the template
				if (entry.settings.length > 0 && template.settings?.length === 0) {
					const newTemplate = getBehaviorTemplate(inputData, getBehaviorName(entry));
					if (newTemplate) template.settings = newTemplate;
				}
				if (template.settings) {
					adjustTemplateRecursively(template.settings[0], entry.settings[0]);
					const nextProperty = getNextQueueEntry(entry);

					if (nextProperty && nextProperty?.settings && nextProperty?.settings.length > 0) {
						const baseCopy = _.cloneDeep(parameterTemplate);
						if (baseCopy.settings && baseCopy.settings[0]) {
							const templateNextProperty = findNEXTProperty(template.settings) as BehaviorParameterTemplate;
							const entryNextProperty = findNEXTProperty(entry.settings) as BehaviorParameter;
							templateNextProperty.settings = [getQueueEntry(baseCopy) as BehaviorParameterTemplate]; // Copying entry
							adjustTemplateRecursively(templateNextProperty, entryNextProperty);
						}
					}
				}
			}
			return template;
		};

		const currentBehaviorSettings = _.cloneDeep(behaviorSettings);
		const newTemplate = _.cloneDeep(parameterTemplate);
		const planningActionQueueSettings = getPlanningAction(currentBehaviorSettings[agentId])?.settings?.find(
			(p) => p.property === "PLANNING_ACTION_QUEUE"
		) as BehaviorParameter;

		const adjusted = adjustTemplateRecursively(newTemplate, planningActionQueueSettings);
		setUpdatedTemplate(adjusted);
	}, [parameterTemplate, agentId, behaviorSettings, inputData]);

	function getBehaviorNameFromSettings() {
		return getBehaviorName(getPlanningAction(behaviorSettings[agentId])) || null;
	}

	const getEntriesList = (
		list: JSX.Element[],
		parameterTemplate: BehaviorParameterTemplate,
		settingsPath: (string | number)[],
		templatePath: (string | number)[]
	) => {
		if (getQueueEntry(parameterTemplate) && inputData.behaviors) {
			const entry = getQueueEntry(parameterTemplate);
			const queueInputIndex = list.length + 1;
			list.push(
				<Flex
					key={settingsPath.join("-")}
					w={"100%"}
					p={"1rem"}
					mb={"1rem"}
					style={{
						border: `1px solid ${disabled ? schemeColors[4] : schemeColors[5]}`,
						flexDirection: "column",
						borderRadius: "0.25rem",
					}}
				>
					<Flex
						w={"100%"}
						style={{
							position: "relative",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
						mb={"1rem"}
					>
						{list.length > 0 && !previewMode ? (
							<ActionIcon
								color={"red"}
								// bg={"red"}
								variant="outline"
								title="Remove"
								mb={"1rem"}
								onClick={() => removeElementFromQueue([...settingsPath], [...templatePath], queueInputIndex)}
							>
								<IconX size="1.1rem"></IconX>
							</ActionIcon>
						) : null}
						<BehaviorSelect
							w={"100%"}
							defaultValue={getQueueName(_.get(behaviorSettings[agentId], [...settingsPath])) || null}
							data={inputData.behaviors
								.filter(
									(behavior) =>
										behavior.type === "PLANNING_ACTION" &&
										behavior.template &&
										behavior.template[0] &&
										behavior.template[0].type !== "actionQueue"
								)
								.map((behavior) => ({ label: behavior.name, value: behavior.name }))}
							onChange={(value) => {
								handleBehaviorChange(
									"PLANNING_ACTION",
									value,
									[...settingsPath, "settings", 0, "settings", 0],
									queueInputIndex
								);

								if (value && parameterTemplate.settings && inputData.behaviors) {
									const newTemplate = getBehaviorTemplate(inputData, value);
									let newBehaviorTemplate = _.cloneDeep(updatedTemplate);

									const planningActionObject = getQueuePlanningAction(
										_.get(newBehaviorTemplate, [...templatePath])
									)?.settings;

									planningActionObject && _.set(planningActionObject, [], newTemplate);
									setUpdatedTemplate(newBehaviorTemplate);
								}
							}}
							disabled={previewMode}
							clearable
						></BehaviorSelect>
					</Flex>
					{entry &&
						entry.settings &&
						entry.settings[0].settings &&
						entry.settings[0].settings?.map((element, ix) => {
							return getParameterInput(
								element as BehaviorParameterTemplate,
								ix,
								[...settingsPath, "settings", 0, "settings", 0, "settings", ix + 1],
								false,
								false,
								queueInputIndex
							);
						})}
					{entry &&
						entry.settings &&
						entry.settings[1].settings &&
						entry.settings[1].settings?.map((element, ix) => {
							return getParameterInput(
								element as BehaviorParameterTemplate,
								ix,
								[...settingsPath, "settings", 0, "settings", 1, "settings", ix],
								false,
								true,
								queueInputIndex
							);
						})}
					{/* If there's already next element, don't show the button */}
					{!(entry && entry.settings && getNextQueueEntry(entry)) && !previewMode ? (
						<Button
							variant="outline"
							onClick={() =>
								addNextToQueue(
									[...settingsPath, "settings", 0, "settings", 2],
									[...templatePath, "settings", 0, "settings", 2]
								)
							}
						>
							Add next
						</Button>
					) : null}
				</Flex>
			);
			if (entry && entry.settings && entry.settings.find((setting) => setting.property === "NEXT")?.settings?.[0]) {
				list = getEntriesList(
					list,
					entry.settings.find((setting) => setting.property === "NEXT") as BehaviorParameterTemplate,
					[...settingsPath, "settings", 0, "settings", 2],
					[...templatePath, "settings", 0, "settings", 2]
				);
			}
		}
		return list;
	};

	const addNextToQueue = (settingsPath: (string | number)[], templatePath: (string | number)[]) => {
		if (parameterTemplate.settings) {
			const newTemplate = _.cloneDeep(updatedTemplate);
			_.set(newTemplate, [...templatePath, "settings"], [parameterTemplate.settings[0]]);
			setUpdatedTemplate(newTemplate);
		}

		const newBehaviorSettings = _.cloneDeep(behaviorSettings);
		const behaviorName = getBehaviorNameFromSettings();

		if (behaviorName) {
			const defaultParameters = getDefaultParameters(behaviorName);
			if (defaultParameters[0] && defaultParameters[0].settings) defaultParameters[0] = defaultParameters[0].settings[0];
			const defaults = [...defaultParameters];
			_.set(newBehaviorSettings[agentId], [...settingsPath, "settings"], defaults);
			setSetting(newBehaviorSettings);
		}
	};

	const removeElementFromQueue = (settingsPath: (string | number)[], templatePath: (string | number)[], queueInputIndex: number) => {
		// Remove the element from the template
		const newTemplate = _.cloneDeep(updatedTemplate);

		if (_.get(updatedTemplate, [...templatePath, "settings", 0, "settings", 2, "settings"]).length > 0) {
			const next = _.get(updatedTemplate, [...templatePath, "settings", 0, "settings", 2, "settings"]);
			_.set(newTemplate, [...templatePath, "settings"], next); // set the current element to be the next element
		} else {
			_.set(newTemplate, [...templatePath, "settings"], []);
		}

		setUpdatedTemplate(newTemplate);

		// Remove the element from the settings
		const newBehaviorSettings = _.cloneDeep(behaviorSettings);

		if (_.get(behaviorSettings[agentId], [...settingsPath, "settings", 0, "settings", 2, "settings"])?.length > 0) {
			const next = _.get(behaviorSettings[agentId], [...settingsPath, "settings", 0, "settings", 2, "settings"]);
			_.set(newBehaviorSettings[agentId], [...settingsPath, "settings"], next); // Set the current element to be the next element
		} else {
			_.set(newBehaviorSettings[agentId], [...settingsPath, "settings"], []);
		}

		setSetting(newBehaviorSettings);

		resetAgentIdEntities(agentId.toString() + "-" + queueInputIndex, true);
	};

	return (
		<Flex style={{ flexDirection: "column" }}>
			{getEntriesList(
				[],
				updatedTemplate,
				[behaviorSettings[agentId]?.findIndex((p) => p.property === "PLANNING_ACTION"), "settings", 1],
				[]
			)}
		</Flex>
	);
};

export default QueueInput;
