import { Button, createStyles, Flex, Group, Modal } from "@mantine/core";
import * as Cesium from "cesium";
import { ImageryLayer, Viewer } from "resium";
import { useEffect, useState } from "react";
import { cesiumMapKey } from "../../behaviors-editor/BehaviorsEditor";
import { AreasData } from "../../../../../types/geometries";
import { createAreas } from "../../behaviors-editor/functions/createAreas";
import { resetAreaColor } from "../functions/resetAreaColor";
import MultiCheckbox from "../../../../../components/common/MultiCheckbox";

interface IAreasSelector {
	value: number[];
	areasData: AreasData;
	onChange: (value: number[]) => void;
}

export const AreasSelector: React.FC<IAreasSelector> = ({ value, areasData, onChange }) => {
	const { classes } = useStyles();
	const [isOpened, setIsOpened] = useState(false);
	const [viewer, setViewer] = useState<Cesium.Viewer | null>(null);
	const [dataSource, setDataSource] = useState<Cesium.GeoJsonDataSource | null>(null);
	const [temporaryValue, setTemporaryValue] = useState<number[]>(value);

	useEffect(() => {
		const onAreaSelected = (selectedEntity: Cesium.Entity) => {
			if (selectedEntity && viewer) {
				const selectedId = selectedEntity.properties?._id._value;
				setTemporaryValue((prev) => {
					if (prev.includes(selectedId)) {
						return prev.filter((v) => v !== selectedId);
					} else {
						return [...prev, selectedId];
					}
				});
			}
		};

		viewer && viewer.selectedEntityChanged.addEventListener(onAreaSelected);

		return () => {
			viewer && viewer.selectedEntityChanged.removeEventListener(onAreaSelected);
		};
	}, [viewer]);

	useEffect(() => {
		if (viewer && areasData && !viewer?.dataSources?.getByName("areas")?.[0]) {
			let newDataSource = new Cesium.GeoJsonDataSource("areas");
			newDataSource.load(areasData);
			const source = newDataSource as Cesium.GeoJsonDataSource;

			createAreas(viewer, source, () => {
				setDataSource(source);

				// Make areas opaque
				const entities = source.entities.values as (Cesium.Entity & { type: string })[];

				const allPositions = entities
					.map((entity) => {
						return entity.polygon?.hierarchy?.getValue(new Cesium.JulianDate(1, 1)).positions;
					})
					.flat();

				const center = Cesium.BoundingSphere.fromPoints(allPositions).center;

				viewer.camera.setView({
					destination: new Cesium.Cartesian3(center.x, center.y, center.z),
					orientation: {
						heading: viewer.camera.heading,
						pitch: Cesium.Math.toRadians(-90),
						roll: viewer.camera.roll,
					},
				});

				viewer.camera.zoomOut(100000);
			});
		}
	}, [viewer, areasData, dataSource]);

	useEffect(() => {
		if (value) {
			setTemporaryValue(value);
		}
	}, [value]);

	useEffect(() => {
		if (!isOpened) onChange(temporaryValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpened, temporaryValue]);

	useEffect(() => {
		if (viewer && dataSource && isOpened && viewer.dataSources) {
			dataSource.entities.values.forEach((entity) => {
				if (temporaryValue.includes(entity.properties?._id._value)) {
					if (entity.polygon && entity.polygon.material) {
						entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.WHITE.withAlpha(0.8));
					}
				} else {
					resetAreaColor(entity, 0.4);
				}
			});
		}
	}, [viewer, dataSource, temporaryValue, isOpened]);

	return (
		<>
			{areasData ? (
				<Modal
					className={classes.modal}
					opened={isOpened}
					onClose={() => {
						viewer?.dataSources.remove(viewer?.dataSources.getByName("areas")[0]);
						setIsOpened(false);
					}}
					title={"Select areas"}
					size={"90vw"}
				>
					<MultiCheckbox
						keyProp={"zones" + temporaryValue.join("")}
						additionalClassName={classes.multiCheckboxInside}
						options={areasData?.features?.map((feature: any) => {
							return {
								value: feature.properties.id,
								label: feature.properties.label,
							};
						})}
						checkedList={temporaryValue}
						onChange={(value) => {
							setTemporaryValue(value as number[]);
						}}
					></MultiCheckbox>
					<Flex h={"80vh"}>
						<Viewer
							full
							className={classes.viewer}
							style={{ width: "100%", height: "100%" }}
							ref={(e) => {
								if (e && e.cesiumElement) {
									setViewer(e.cesiumElement);
								}
							}}
							animation={false}
							timeline={false}
							baseLayerPicker={false}
							navigationHelpButton={false}
							sceneModePicker={false}
							homeButton={false}
							geocoder={false}
							fullscreenButton={false}
							selectionIndicator={false}
						>
							<ImageryLayer
								imageryProvider={
									new Cesium.UrlTemplateImageryProvider({
										url: `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${cesiumMapKey}`,
										minimumLevel: 0,
										maximumLevel: 20,
										credit: new Cesium.Credit(
											'\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
											true
										),
									})
								}
							/>
						</Viewer>
					</Flex>
				</Modal>
			) : null}

			<Group position="center">
				<MultiCheckbox
					keyProp={"zones-standalone" + temporaryValue.join("")}
					additionalClassName={classes.multiCheckboxOutside}
					options={areasData.features?.map((feature: any) => {
						return {
							value: feature.properties.id,
							label: feature.properties.label,
						};
					})}
					checkedList={temporaryValue}
					onChange={(value) => {
						setTemporaryValue(value as number[]);
					}}
				></MultiCheckbox>
				<Button w={"100%"} onClick={() => setIsOpened(true)} component="div" variant={"filled"} loading={!areasData}>
					{/* component="div" is needed to prevent html from throwing wrong nesting error if used inside Accordion.Control */}
					Select zones
				</Button>
			</Group>
		</>
	);
};

const useStyles = createStyles((theme, _params, _variations) => ({
	viewer: {
		".cesium-viewer-bottom": {
			right: 0,
			left: "auto !important",
		},
	},

	modal: {
		".mantine-Modal-header": {
			height: "3rem",
		},

		".mantine-Modal-body": {
			position: "relative",
		},
	},

	multiCheckboxInside: {
		zIndex: 10,
		position: "absolute",
		top: "0",
		left: "0",
		height: "100%",
		width: "12rem",
		padding: "1rem",
		backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.light[1],
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},

	multiCheckboxOutside: {},
}));
