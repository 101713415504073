import styled from "@emotion/styled/macro";
import { Button, ButtonProps, useMantineTheme } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { IconChartAreaLine, IconWorld } from "@tabler/icons-react";
import { useContext } from "react";
import { AppInfoContext } from "../contexts/AppInfoContext";

interface IOtherProjectsButtonsProps {
	reporterUrl: string;
	cesiumUrl: string;
}

export const OtherProjectsButtons: React.FC<IOtherProjectsButtonsProps> = ({ reporterUrl, cesiumUrl }) => {
	const theme = useMantineTheme();
	const { setPid } = useContext(AppInfoContext);

	return (
		<>
			<ReporterButton
				bg={theme.colors.blue[7]}
				onClick={() => {
					setPid(0);
					window.open(reporterUrl, "_self");
				}}
				leftIcon={<IconChartAreaLine></IconChartAreaLine>}
			>
				{"Reporter"}
			</ReporterButton>
			<CesiumButton
				bg={theme.colors.yellow[7]}
				onClick={() => {
					setPid(0);
					window.open(cesiumUrl, "_self");
				}}
				leftIcon={<IconWorld></IconWorld>}
			>
				{"Cesium"}
			</CesiumButton>
		</>
	);
};

const ReporterButton = styled(Button)<PolymorphicComponentProps<"button", ButtonProps>>`
	&:hover {
		background-color: ${({ theme }) => theme.colors.blue[8]};
	}
`;

const CesiumButton = styled(Button)<PolymorphicComponentProps<"button", ButtonProps>>`
	&:hover {
		background-color: ${({ theme }) => theme.colors.yellow[8]};
	}
`;
