import * as Cesium from "cesium";

export const createAreas = (viewer: Cesium.Viewer, areasDataSource: Cesium.GeoJsonDataSource, callback?: () => void) => {
	const exists = viewer?.dataSources?.getByName("areas")[0];

	if (!exists && viewer && viewer.dataSources) {
		areasDataSource.name = "areas";
		viewer.dataSources.add(areasDataSource).then(() => {
			const entities = areasDataSource.entities.values as (Cesium.Entity & { type: string })[];

			entities.forEach((entity) => {
				var color = null;

				if (entity.properties) {
					const type = entity.properties.type._value;
					if (type.indexOf("Blue") !== -1) {
						color = new Cesium.ColorMaterialProperty(Cesium.Color.BLUE.withAlpha(0.4));
					} else if (type.indexOf("Red") !== -1) {
						color = new Cesium.ColorMaterialProperty(Cesium.Color.RED.withAlpha(0.4));
					}

					entity.type = "area";
					entity.name = entity.properties.type._value;

					if (entity.polygon) {
						entity.polygon.material = color as Cesium.ColorMaterialProperty;
						entity.polygon.outlineColor = undefined;
					}
				}
			});

			if (callback) {
				callback();
			}
		});
	}
};
