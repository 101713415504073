import React from "react";
import styled from "@emotion/styled/macro";
import { useNavigate, useParams } from "react-router-dom";
import { Button, createStyles, Flex } from "@mantine/core";
import { OtherProjectsButtons } from "../../components/common/OtherProjectsButtons";

const ProjectMenu: React.FC = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { pid } = useParams();

	return (
		<Flex direction={"column"} h={"100%"}>
			<MenuContainer>
				<Button className={classes.menuBtn} onClick={() => navigate("systems-library")}>
					{"Systems library"}
				</Button>
				<Button className={classes.menuBtn} onClick={() => navigate("force-packages")}>
					{"Force packages"}
				</Button>
				<Button className={classes.menuBtn} onClick={() => navigate("assessments")}>
					{"Assessments"}
				</Button>
			</MenuContainer>

			<OthersContainer>
				<OtherProjectsButtons reporterUrl={`/reporter/project/${pid}`} cesiumUrl={`/cesium/project/${pid}`} />
			</OthersContainer>
		</Flex>
	);
};

const useStyles = createStyles((theme) => ({
	menuBtn: {
		margin: "0.5rem",
	},
}));

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
	padding: 0 10rem;
	justify-content: center;

	& button {
		width: 12rem;
		height: 12rem;
	}

	@media (max-width: 768px) {
		padding: 0;
		& button {
			width: 12rem;
			height: 12rem;
		}
	}
}`;

const OthersContainer = styled(Flex)`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 10rem;

	& button {
		width: 12rem;
		height: 12rem;
	}
`;

export default ProjectMenu;
