import { useEffect, useState } from "react";
import { Accordion, Flex, createStyles } from "@mantine/core";
import { Panel } from "../../../AppPage";
import { ForcePackageData, ForcePackagesFiltersData, Optional } from "../../../types/filters";
import styled from "@emotion/styled/macro";
import Preloader from "../../../components/common/Preloader";
import ElementsList from "../../../components/elements-list/ElementsList";
import { defaultSettings } from "../defaultSettings";

interface IForcePackagesList {
	name: string;
	list: ForcePackagesFiltersData;
	filtersLoading: boolean;
	getForcePackageSettings: (systemId: number) => Promise<any>;
	setForcePackagesLoading: (value: boolean) => void;
	initializeNewSystem: (newSettings: Optional<ForcePackageData, "id">) => void;
	deleteSystem: (settingsId: number) => Promise<any>;
	duplicateSystem: (settingsId: number) => Promise<any>;
	reloadList: () => Promise<any>;
}

const ForcePackagesList: React.FC<IForcePackagesList> = ({
	name,
	list,
	filtersLoading,
	getForcePackageSettings,
	setForcePackagesLoading,
	initializeNewSystem,
	deleteSystem,
	duplicateSystem,
	reloadList,
}) => {
	const [currentList, setCurrentList] = useState<ForcePackagesFiltersData>(list);
	const [currentlyOpened, setCurrentlyOpened] = useState<string | null>(null);
	const { classes } = useStyles();

	useEffect(() => {
		setCurrentList(list);
	}, [list]);

	return (
		<PanelContainer>
			{!filtersLoading ? (
				<>
					{name.length > 0 ? <h3 style={{ height: "fit-content" }}>{name}</h3> : null}
					<ListWrapper>
						<AccordionContainer onChange={(value) => setCurrentlyOpened(value as string)} defaultValue={currentlyOpened}>
							{Object.values(currentList).map((fp, ix) => {
								return (
									<AccordionItem key={ix} value={fp.name}>
										<AccordionControl>{fp.name}</AccordionControl>
										<Accordion.Panel>
											<ElementsList
												name=""
												list={fp.fps}
												filtersLoading={filtersLoading}
												getElementSettings={getForcePackageSettings}
												setSettingsLoading={(value: boolean) => setForcePackagesLoading(value)}
												reloadFilters={reloadList}
												initializeNewSystem={() => {
													const newSettings = {
														...defaultSettings,
														type: ix + 1,
														fp: {
															...defaultSettings.fp,
															type: (ix + 1).toString(),
														},
													};
													newSettings.fp.echelons[0].type = ix + 1;
													newSettings.fp.echelons[0].is_lego = ix + 1 < 4;
													initializeNewSystem(newSettings);
												}}
												deleteSystem={deleteSystem}
												duplicateSystem={duplicateSystem}
												additionalClasses={classes.fpList}
												iconsList={["edit", "duplicate", "delete", "graph"]}
											/>
										</Accordion.Panel>
									</AccordionItem>
								);
							})}
						</AccordionContainer>
					</ListWrapper>
				</>
			) : (
				<Preloader></Preloader>
			)}
		</PanelContainer>
	);
};

const useStyles = createStyles((theme) => ({
	fpList: {
		backgroundColor: "red",
		padding: "0 !important",
		marginTop: "0 !important",
	},
}));

const AccordionContainer = styled(Accordion)`
	& :last-of-type {
		border-bottom: none;
	}

	& .mantine-Accordion-content > :first-child {
		border-radius: 0.25rem;
	}
`;

const AccordionControl = styled(Accordion.Control)`
	color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.light[2])};
	background-color: ${({ theme }) => (theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.light[1])};
	margin-bottom: 0.5rem;
`;

const AccordionItem = styled(Accordion.Item)`
	border-bottom: none;
`;

const PanelContainer = styled(Panel)`
	overflow-y: auto;
	scrollbar-gutter: auto;
	height: fit-content;
`;

const ListWrapper = styled(Flex)`
	flex-direction: column;
	overflow-y: auto;
	scrollbar-gutter: stable;
	padding-bottom: 5px;

	@media (max-width: 768px) {
	}
`;

export default ForcePackagesList;
